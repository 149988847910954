import React from 'react'
import PageHead from 'src/components/head/PageHead'
import {
  NewslatterWrapper,
  SubscribeInvestorForm,
  SubscribeSuspended,
} from 'src/components/newsletter'

import { SUBSCRIBE_SUSPENDED } from 'src/constants'

// Main component:
const InvestorNewsletter = () => (
  <NewslatterWrapper>
    {({ animate }) =>
      SUBSCRIBE_SUSPENDED ? (
        <SubscribeSuspended animate={animate} />
      ) : (
        <SubscribeInvestorForm animate={animate} />
      )
    }
  </NewslatterWrapper>
)

export default InvestorNewsletter

// <head> component:
export function Head() {
  return <PageHead page="investor-newsletter" />
}
